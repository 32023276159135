<template>
  <div v-loading="isLoading" class="referral">
    <div class="referral__content">
      <div class="sidebar__content">
      <p class="referral-link__title">Просмотр реферальной ссылки</p>
      <div>
        <p>Название: {{ referralLinkInfo && referralLinkInfo.title }}</p>
      </div>
      <div class="referral-form-container">
        <div v-for="(link, index) in links" :key="index" class="referral-link-container">
          <a :href="link.url" :title="link.url" class="referral-link-link" target="_blank" rel="noopener noreferrer">
            {{ link.url }}
          </a>
          <button class="referral-link_copy" @click="copyToClipboard(link.url, $event)">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M14.75 3.5H5.25C5.05109 3.5 4.86032 3.57902 4.71967 3.71967C4.57902 3.86032 4.5 4.05109 4.5 4.25V16.25C4.5 16.4489 4.57902 16.6397 4.71967 16.7803C4.86032 16.921 5.05109 17 5.25 17H14.75C14.9489 17 15.1397 16.921 15.2803 16.7803C15.421 16.6397 15.5 16.4489 15.5 16.25V4.25C15.5 4.05109 15.421 3.86032 15.2803 3.71967C15.1397 3.57902 14.9489 3.5 14.75 3.5ZM14.5 16H5.5V4.5H14.5V16Z"
                class="path"
              />
              <path
                d="M13 1.75C13 1.55109 12.921 1.36032 12.7803 1.21967C12.6397 1.07902 12.4489 1 12.25 1H2.75C2.55109 1 2.36032 1.07902 2.21967 1.21967C2.07902 1.36032 2 1.55109 2 1.75V13.75C2 13.9489 2.07902 14.1397 2.21967 14.2803C2.36032 14.421 2.55109 14.5 2.75 14.5H3V2H13V1.75Z"
                class="path"
              />
            </svg>
          </button>
        </div>
      </div>
      <table class="referral__table-container">
        <thead>
          <tr>
            <th>Платформы</th>
            <th>Текущий грейд</th>
            <th>Процент реферала</th>
            <th>Мой процент</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(service, index) in referralLinkInfo.services">
            <tr
              v-if="service.default_percent && service.referral_percent" 
              :key="index"
            >
              <td class="referral-platform">{{ ServiceName[service.service.toUpperCase()] }}</td>
              <td>{{ service.default_percent }}</td>
              <td>{{ Number(service.referral_percent).toFixed(2) }}</td>
              <td class="referral-my_precent">
                <div>
                  {{ Number(service.percent).toFixed(2) }}
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table> 
    </div>
  </div>
</div>
</template>

<script>
import { ServiceName } from "@/utils/services"
import { copyText } from "@/utils/helpers" 

  export default {
    data() {
    return {
      ServiceName,
      referralLinkInfo: [],
      agentsList: {},
      isLoading: true,
      platforms: {
        avito: {
          grade : 0, 
          percent: 0
        },
        mytarge : {
          grade : 13, 
          percent: 7.7
        },
        vkads : {
          grade : 13, 
          percent: 4.5
        },
        yandex : {
          grade : 7, 
          percent: 2.1
        },
      }
    };
  },
    computed: {
      links() {
        return [
          {
            url: `${window.location.origin}/registration/${this.referralLinkInfo.referral_code}`
          },
          {
            url: `${window.location.origin}/?agent_id=${this.referralLinkInfo.referral_code}`.replace('lk.', '')
          }
        ]
      },
      referralId() {
        const id = this.$store.state.Dialog.dialogData?.referralId || '';
        return id;
      },
      agent() {
        const id = this.$store.state.Dialog.dialogData?.agent || '';
        return id;
      },
      agentsArray() {
      if (!this.agentsList) return [];
      return Object.values(this.agentsList);
    },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      copyToClipboard(text, event) {
        copyText(text, () => {
          this.$notify({
            message: 'Ссылка скопирована!',
            type: 'success'
          });
        })
      },
    },
    async mounted() {
      console.log('On Mounted?')
      const clientToken = localStorage.getItem('client_token');
      const data = { token: clientToken, code: this.referralId, agent: this.agent.id  };
      try {
        const response = await this.$store.dispatch('Referrals/getReferralLinkInfoByAgentAndCode', data);
        this.referralLinkInfo = response.data.data
        this.isLoading = false
      } catch(e) {
        console.error(e)
        this.isLoading = false
      }
    }
  };
</script>

<style scoped lang="scss">
  .referral {
    font-family: "Roboto", serif;
    background: #fff;
    color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__content {
      padding: 70px 100px 0 80px;
      width: 100%;
    }

    &-form {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      &-container {
        display: flex;
        column-gap: 20px;
        margin-bottom: 40px;
        margin-top: 20px;
      }
    }

    &-platform {
      font-size: 16px;
      font-weight: 400;
      line-height: 18.75px;
      color: #9BA2AB;
      
    }

    &-my_precent {
      div {
        border: 1px solid #D1D4D8;
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.75px;
        max-width: 100px;
        margin: 0 auto;
        padding: 10px 0;
        text-align: center;
        border-radius: 2px;
      }
    }

    &-link {
      &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 23.44px;
        color: #333333;
        margin-bottom: 20px;
      }

      &-container {
        max-width: 282px;
        width: 100%;
        border: 1px solid #D1D4D8;
        border-radius: 2px;
        padding: 15px 14px;
        display: flex;
        column-gap: 6px;
        justify-content: space-between;
      }

      &-link {
        font-size: 16px;
        font-weight: 500;
        line-height: 18.75px;
        color: #333333;
        text-decoration: underline;
        display: inline-block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &_copy {
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;

        &:hover {
          cursor: pointer;
        }

        svg {
          path {
            fill: #9BA2AB;
          }
        }
      }
    }
  }

  .sidebar__content {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 40px;
  }

  .referral__table-container {
    overflow-y: auto;
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    border-bottom: 1px solid #BFC2C6;
    padding-bottom: 12px;
  }

  table th,
  table td {
    border: none;
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    color: #333333;
  }

  table th {
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    color: #BFC2C6;
  }
</style>